<template>
  <div>

    <div>
      <span
        class="downloadIcon"
      >
        <v-icon
          color="blue darken-1"
          @click="downloadChart"
        >
          {{ this.downloading ? 'mdi-loading mdi-spin' : 'mdi-download' }}
        </v-icon>
      </span>
      <a ref="downloadLink" :href="downloadUrl" download="chart.png" style="display: none"></a>

      <Doughnut
          :chart-data="preparedChartData"
          :chart-options="chartOptions"
          :chart-id="'doughnut-chart'"
          :dataset-id-key="'label'"
          :plugins="[]"
          :css-classes="''"
          :styles="{}"
          :width="300"
          :height="300"
      />
    </div>

    <!--
      Render a seperate, slightly different chart for downloading.
      We render it fullscreen so the legend is not (unlikely to) be cut off on smaller screensizes.
    -->
    <div
      v-if="downloading"
      style="position: fixed; top:0 ; left: 0; opacity: 0;"
    >
      <Doughnut
        ref="canvasDownloadRef"
        :chart-data="preparedChartDataDownload"
        :chart-options="chartDownloadOptions"
        :chart-id="'doughnut-chart'"
        :dataset-id-key="'label'"
        :plugins="plugins"
        :css-classes="''"
        :styles="{}"
        :width="500"
        :height="500"
      />
    </div>
  </div>
</template>

<script>
// this is how they do it in docs
// eslint-disable-next-line import/extensions
import { Doughnut } from 'vue-chartjs/legacy';
import {
  ArcElement, CategoryScale, Chart as ChartJS, Legend, SubTitle, Title, Tooltip,
} from 'chart.js';

import { sortVoteResults } from '@/helpers/voteResultsHelper';

// Register Chart.js components
ChartJS.register(Title, SubTitle, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  name: 'DoughnutChart',
  components: {
    Doughnut,
  },
  props: {
    voteData: {
      type: Array,
      required: true,
    },
    voteDistribution: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chartData: null,

      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          subtitle: {
            display: true,
            position: 'bottom',
            align: 'start',
            text: this.$t('generic.poweredByPolpoNl'),
          },
        },
      },

      chartDownloadOptions: {
        responsive: false,
        maintainAspectRatio: false,
        layout: {
          padding: 10,
        },
        plugins: {
          legend: {
            position: 'right',
            display: true,
          },
          subtitle: {
            display: true,
            position: 'bottom',
            align: 'start',
            text: this.$t('generic.poweredByPolpoNl'),
          },
          title: {
            display: true,
            position: 'top',
            align: 'center',
            text: '',
            font: {
              size: 14,
            },
          },
          customCanvasBackgroundColor: {
            color: 'white',
          },
          customTextBottomLeft: true,
        },
      },

      // Plugin that allows us to set a fixed-color background
      plugins: [{
        id: 'customCanvasBackgroundColor',
        beforeDraw: (chart, _, options) => {
          const { ctx } = chart;
          ctx.save();
          ctx.globalCompositeOperation = 'destination-over';
          ctx.fillStyle = options.color || '#99ffff';
          ctx.fillRect(0, 0, chart.width, chart.height);
          ctx.restore();
        },
      }],


      downloading: false,
      downloadUrl: '',
    };
  },

  mounted() {
    this.updateChartTitle();
  },

  computed: {
    sortedVoteDataForChart() {
      return sortVoteResults(this.voteData, true);
    },
    preparedChartData() {
      return {
        labels: this.sortedVoteDataForChart.map((item) => item.actorName),
        datasets: [
          {
            label: 'Vote Distribution',
            data: this.sortedVoteDataForChart.map((item) => Number(item.fractionSize)),
            backgroundColor: this.sortedVoteDataForChart.map((item) => {
              if (item.voteKind === 'Voor') return '#37ada2';
              if (item.voteKind === 'Tegen') return '#e64415';
              return '#bbb'; // 'Niet deelgenomen'
            }),
          },
        ],
      };
    },
    preparedChartDataDownload() {
      return {
        ...this.preparedChartData,
        labels: this.sortedVoteDataForChart.map((item) => `${item.actorName} (${item.fractionSize})`),
      };
    },
  },

  methods: {
    async downloadChart() {
      this.downloading = true;

      await this.$nextTick();

      // Wait till it rendered: (this is hopefully always enough time)
      setTimeout(() => {
        // eslint-disable-next-line no-underscore-dangle
        const chart = this.$refs.canvasDownloadRef?.$data._chart;

        if (!chart) {
          this.$store.dispatch('setMessage', {
            message: this.$t('generic.errorDownloadingGraph'),
            type: 'error',
          });
          return;
        }
        this.downloadUrl = chart.canvas.toDataURL('image/png');
        this.$nextTick(() => {
          this.$refs.downloadLink.click();
        });
        setTimeout(() => { this.downloading = false; }, 500);
      }, 1000);
    },
    updateChartTitle() {
      const { against, didntParticipate, for: forVote } = this.voteDistribution;
      this.chartDownloadOptions.plugins.title.text = `Voor: ${forVote.count} | Tegen: ${against.count}${didntParticipate.count ? ` | Niet deelgenomen: ${didntParticipate.count}` : ''}`;
    },
  },

};
</script>

<style scoped lang="scss">
.downloadIcon {
  display: flex;
  max-height: 0;
  overflow: visible;
  justify-content: flex-end;

  .v-icon {
    top: 1em;
    margin-right: 0.5em;
  }
}
</style>
