<template>
  <v-expansion-panels
    class="my-1"
    multiple
    :value="[0]"
  >
    <v-expansion-panel>
      <v-card-text class="py-0">
        <v-expansion-panel-header class="px-0">
          <div class="d-flex">
            <span class="title-font align-self-center">{{
              $t('debates.summaryAI')
            }}</span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="summary-wrapper">
          <div
            class="d-flex justify-space-between"
          >
            <div
              :class="`summary-content ${theme}`"
              v-html="summary"
            />
            <div>
              <button
                class="disclaimer-button"
                @click.stop="showDisclaimer()"
                icon
              >
                <v-icon color="orange">mdi-information</v-icon>
              </button>
            </div>
          </div>
        </v-expansion-panel-content>
      </v-card-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { showDialog } from '../../../helpers/dialogHelper';
import DisclaimerTextDialog from './DisclaimerTextDialog.vue';

export default {
  name: 'DocumentSummary',

  props: {
    summary: {
      type: String,
      required: true,
    },
  },

  computed: {
    theme() {
      return this.$vuetify.theme.dark ? 'darkMode' : 'lightMode';
    },
  },

  methods: {
    showDisclaimer() {
      showDialog(DisclaimerTextDialog, {
        theme: this.theme,
        title: this.$t('debates.summaryDisclaimerTitle'),
        body: this.$t('debates.summaryDisclaimerBody'),
        footer: this.$t('debates.summaryDisclaimerFooter'),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.title-font {
  color: teal;
  font-weight: 600;
}

.disclaimer-button {
  height: 24px;
  width: 24px;
}

.summary-content {
  ::v-deep h3 {
    margin-bottom: 0.7em;
  }
  ::v-deep ul li {
    margin-bottom: 0.4em;
  }

  &.lightMode {
    color: rgba(0, 0, 0, 0.9);
  }

  &.darkMode {
    color: rgba(255, 255, 255, 0.7);
  }
}

.v-expansion-panel-content.summary-wrapper
  >>> .v-expansion-panel-content__wrap {
  padding: 0;
  padding-bottom: 16px; // Does not make a difference local but does on TEST ???
}
</style>
