import { render, staticRenderFns } from "./DocumentLayout.vue?vue&type=template&id=30562b9f&scoped=true"
import script from "./DocumentLayout.vue?vue&type=script&lang=js"
export * from "./DocumentLayout.vue?vue&type=script&lang=js"
import style0 from "./DocumentLayout.vue?vue&type=style&index=0&id=30562b9f&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30562b9f",
  null
  
)

export default component.exports