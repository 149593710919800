<template>
  <v-expansion-panels
    v-model="panel"
    multiple
  >
    <dlrc-basic-details
      v-if="shouldShowBasicDetails"
      :meetings="meetings"
      :voteResults="voteResults"
    />
    <dlrc-references
      :refs="refs"
      :id="documentId"
    />
  </v-expansion-panels>
</template>

<script>
import DlrcBasicDetails from './DlrcBasicDetails.vue';
import DlrcReferences from './DlrcReferences.vue';

export default {
  name: 'nl-lr-detail-sidebar',

  components: {
    DlrcReferences,
    DlrcBasicDetails,
  },

  props: {
    document: {
      type: Object,
      required: true,
    },
    documentId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      panel: [0, 1],
    };
  },

  computed: {
    meetings() {
      if (!this.document.related) return [];
      // Quick fix because there is a bug in Parlaeus Gelderland with sometime url are missing.
      // TODO: this should be fixed in the backend when we have more time on our hands.
      // TODO: create a ticket if there isn't one yet, and add the ticket number here!
      let missingUrl;
      const dlrcAgendas = [];
      if (this.document.related.meetings && this.document.related.meetings.length > 0) {
        this.document.related.meetings.forEach((item) => {
          item.meeting = true;
          // Quick fix
          if (this.document.type.startsWith('PC-GEL-')) {
            missingUrl = item.url;
          }
          dlrcAgendas.push(item);
        });
      }
      if (this.document.related.agendaItems && this.document.related.agendaItems.length > 0) {
        this.document.related.agendaItems.forEach((item) => {
          item.agendaItem = true;
          // Quick fix
          if (this.document.type.startsWith('PC-GEL-') && !item.url && missingUrl) {
            item.url = missingUrl;
          }
          dlrcAgendas.push(item);
        });
      }

      return dlrcAgendas;
    },

    voteResults() {
      // Some documents like 'moties' have voteResults
      return this.document.related?.voteResults;
    },

    refs() {
      // EUROPARL refs
      const europarl = [];
      if (this.document.reference) {
        europarl.push(this.document.reference);
      }
      if (this.document.relations) {
        this.document.relations.forEach((item) => {
          if (['PROCEDURE', 'DOSSIER'].includes(item.type)) {
            europarl.push(item.code);
          }
        });
      }

      // DLRC ref(s)
      const dlrc = [];
      if (this.document.related && this.document.related.dossier) {
        const { url } = this.document.related.dossier;
        if (url) dlrc.push(url);
      }

      // Combine the different refs into one object
      let result = {};
      if (europarl.length > 0) result = { europarl };
      if (dlrc.length > 0) result = { ...result, dlrc };
      return result;
    },

    shouldShowBasicDetails() {
      return !!(this.document?.related?.voteResults?.overallResult || this.meetings.length > 0);
    },
  },
};
</script>
