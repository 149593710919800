<template>
  <v-expansion-panels
    v-model="panel"
    multiple
  >
    <generic-basic-details
      v-if="shouldShowBasicDetails"
      :publicIds="publicIds"
      :relatedDossiers="document.relatedDossiers"
      :relatedPeoplePlain="document.relatedPeoplePlain"
      :relatedOrganizationsPlain="document.relatedOrganizationsPlain"
      :relatedUrls="relatedUrls"
      :futureEvents="processedEvents.futureEvents"
      :pastEvents="processedEvents.pastEvents"
      :proceduralStatuses="reversedStatuses"
      :voteResults="voteResults"
    />

    <list-subjects
      v-if="shouldShowWordCloud"
      :subjects="subjects"
    />

    <generic-references
      :items="document.relatedDocuments"
    />
  </v-expansion-panels>
</template>

<script>
import GenericBasicDetails from './GenericBasicDetails.vue';
import GenericReferences from './GenericReferences.vue';
import ListSubjects from './ListSubjects.vue';

export default {
  name: 'generic-detail-sidebar',

  components: {
    GenericReferences,
    GenericBasicDetails,
    ListSubjects,
  },

  props: {
    document: {
      type: Object,
      required: true,
    },
    documentId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      panel: [0, 1, 2],
    };
  },

  computed: {
    subjects() {
      return this.document.subjects;
    },

    publicIds() {
      return this.document.publicIds || [];
    },

    reversedStatuses() {
      return (this.document.proceduralStatuses ?? []).reverse();
    },

    relatedUrls() {
      return (this.document.relatedUrls ?? [])
        .filter((relatedUrl) => relatedUrl.type && relatedUrl.url); // Skip any related URLs that do not have both a type to show, and a URL to link to
    },

    processedEvents() {
      // Get the relatedEvents, and the relatedDossiers which might contain further relatedEvents
      const { relatedEvents, relatedDossiers } = this.document;
      // Process the relatedDossiers
      const eventsFromDossiers = relatedDossiers.reduce(
        (events, dossier) => {
          const { entity: { relatedEvents: curDosEvents = [] } } = dossier;
          return [...events, ...curDosEvents];
        },
        [],
      );
      // Merge both sets of relatedEvents into one list
      const mergedEvents = [...relatedEvents, ...eventsFromDossiers];
      if (!mergedEvents?.length > 0) {
        return {
          futureEvents: [],
          pastEvents: [],
        };
      }

      // Sort all events (descending time order)
      mergedEvents.sort((a, b) => new Date(b.entity?.startDateTime)
       - new Date(a.entity?.startDateTime));

      // Process events to split into two lists; past and future
      const futureEvents = [];
      const pastEvents = [];
      mergedEvents.forEach((relatedEvent) => {
        const {
          relationType,
          entity: {
            startDateTime,
            endDateTime,
            title,
            type,
            url,
          },
        } = relatedEvent;

        const startDate = startDateTime ? this.dateAsDateString(startDateTime) : null;
        const startTime = startDateTime ? this.dateAsTimeString(startDateTime) : null;
        const endDate = endDateTime ? this.dateAsDateString(endDateTime) : null;
        const endTime = endDateTime ? this.dateAsTimeString(endDateTime) : null;

        const processedEvent = {
          relationType,
          startDate,
          startTime,
          endDate,
          endTime,
          title,
          type,
          url,
        };
        const date = new Date(startDateTime);

        if (date > Date.now()) {
          futureEvents.push(processedEvent);
        } else {
          pastEvents.push(processedEvent);
        }
      });

      return {
        futureEvents,
        pastEvents,
      };
    },

    voteResults() {
      // Some documents have voteResults
      return this.document.related?.voteResults;
    },

    refs() {
      // TODO: determine if we need any of this for BE-FED!
      // EUROPARL refs
      const europarl = [];
      if (this.document.reference) {
        europarl.push(this.document.reference);
      }
      if (this.document.relations) {
        this.document.relations.forEach((item) => {
          if (['PROCEDURE', 'DOSSIER'].includes(item.type)) {
            europarl.push(item.code);
          }
        });
      }

      // DLRC ref(s)
      const dlrc = [];
      if (this.document.related && this.document.related.dossier) {
        const { url } = this.document.related.dossier;
        if (url) dlrc.push(url);
      }

      // Combine the different refs into one object
      let result = {};
      if (europarl.length > 0) result = { europarl };
      if (dlrc.length > 0) result = { ...result, dlrc };
      return result;
    },

    // TODO: POLMONNL-6203 - Remove either this or GenericBasicDetails.shouldShow() ?
    shouldShowBasicDetails() {
      return !!(
        this.document?.related?.voteResults?.overallResult
        || this.processedEvents.futureEvents.length > 0
        || this.processedEvents.pastEvents.length > 0
        || this.publicIds.length > 0
        || this.document.relatedOrganizationsPlain?.length > 0
        || this.document.relatedPeoplePlain?.length > 0
        || this.document.proceduralStatuses?.length > 0
      );
    },

    shouldShowWordCloud() {
      return !!(this.document?.subjects?.length > 0);
    },
  },

  methods: {
    dateAsDateString(date) {
      return this.$moment(date).format('DD-MM-YYYY');
    },
    dateAsTimeString(date) {
      return this.$moment(date).format('HH:mm');
    },
  },
};
</script>
