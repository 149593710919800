<template>
  <v-expansion-panel
    v-if="shouldShow"
    class=""
  >
    <v-expansion-panel-header>
      <span>{{ $t('detail.documentData')}}</span>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <!-- Document number(s) -->
      <div v-if="publicIds.length">
        <span class="text-body-2 font-weight-bold">{{ $t('detail.numberS')}}</span>
        <ul>
          <li v-for="(publicId, index) in publicIds" :key="index">
            <span class="text-body-2 font-weight-bold">{{ publicId }}</span>
          </li>
        </ul>
      </div>
      <!-- Related Dossiers -->
      <div v-if="relatedDossiers.length" class="mt-4">
        <span class="text-body-2 font-weight-bold">{{ $t('detail.fileS')}}</span>
        <ul>
          <li v-for="(relatedDossier, index) in relatedDossiers" :key="index">
            <a
              :href="relatedDossier.entity.url"
              target="_blank"
              :class="relatedDossier.entity.url ? '': 'disabled'"
            >
              <span class="text-body-2"><span class="font-weight-bold">{{ relatedDossier.entity.publicIds.join(', ') }} </span>
               - {{ relatedDossier.entity.title }}</span>
              {{ relatedDossier.entity.url ? '&nbsp;&#187;' : ''}}
            </a>
          </li>
        </ul>
      </div>
      <!-- Past and future events -->
      <div v-if="futureEvents?.length || pastEvents?.length" class="mt-4">
        <span class="text-body-2 font-weight-bold"> {{ $t('agenda.onTheAgenda') }} </span>
        <!-- Future events, taken from: DlrcBasicDetails.vue -->
        <div v-if="futureEvents?.length">
          <ul class="text-body-2 plain-li">
            <li
              v-for="(event, index) in futureEvents"
              :key="index"
              class="mb-1"
              :class="{ 'mt-2': index === 0 }"
            >
              <a
                :href="event.url"
                target="_blank"
                :class="event.url ? '': 'disabled'"
                class="text-body-2"
              >
                <strong>{{ event.startDate ? `${event.startDate} ` : '' }}</strong>
                {{ event.startTime ? `(${event.startTime}${event.endTime ? ` - ${event.endTime}` : ''}` : '' }})
                {{ event.title !== event.type ? ` - ${event.title}` : '' }}
                {{ event.url ? '&nbsp;&#187;' : ''}}
              </a>
            </li>
          </ul>
        </div>
        <!-- Past events, taken from: BasicDetails.vue -->
        <div v-if="pastEvents?.length">
          <span class="text-body-2 font-weight-bold">{{ $t('detail.concluded')}}:</span>
          <ul class="text-body-2 plain-li past-list">
            <li
              v-for="(event, index) in displayedList('pastEvents')"
              :key="index"
              class="check mb-1"
              :class="{ 'mt-2': index === 0 }"
            >
              <span v-if="event.title">
                <a
                  :href="event.url"
                  target="_blank"
                  :class="event.url ? '': 'disabled'"
                  class="text-body-2"
                >
                  <strong>{{ event.startDate ? `${event.startDate} ` : '' }}</strong>
                  {{ event.startTime ? `(${event.startTime}${event.endTime ? ` - ${event.endTime}` : ''}` : '' }})
                  {{ event.title !== event.type ? ` - ${event.title}` : '' }}
                  {{ event.url ? '&nbsp;&#187;' : ''}}
                </a>
              </span>
            </li>
          </ul>
          <show-more-button
            :show="showAllItems.pastEvents"
            @update:show="value => showAllItems.pastEvents = value"
            v-if="pastEvents.length > this.foldedItemsLimit"
          />
        </div>
      </div>
      <!-- Related people and organizations(plain) -->
      <div v-if="relatedPeoplePlain?.length > 0 || relatedOrganizationsPlain?.length > 0" class="mt-4">
        <span class="text-body-2 font-weight-bold">{{ $t('detail.related.involved') }}</span>
        <ul>
          <li v-for="(relatedPerson, index) in relatedPeoplePlain" :key="index">
            <span class="text-body-2 font-weight-bold">{{ safeI18n(`detail.related.peopleRelations.${relatedPerson.relationType}`, fallbackPerson) }}: </span>
            <span class="text-body-2">{{ relatedPerson.fullName }}</span>
          </li>
          <li v-for="(relatedOrganization, index) in relatedOrganizationsPlain" :key="index">
            <span class="text-body-2 font-weight-bold">{{ safeI18n(`detail.related.organizationsRelations.${relatedOrganization.relationType}`, fallbackOrg) }}: </span>
            <span class="text-body-2">{{ relatedOrganization.name }}</span>
          </li>
        </ul>
      </div>
      <!-- Related URLs (used for "attachments" that are just links, nothing else) -->
      <div v-if="relatedUrls.length" class="mt-4">
        <span class="text-body-2 font-weight-bold">{{ $t('detail.related.urlS')}}</span>
        <ul class="text-body-2 plain-li">
          <li
            v-for="(relatedUrl, index) in relatedUrls"
            :key="index"
            class="mb-1"
          >
            <a :href="relatedUrl.url" target="_blank">
              <span class="text-body-2 font-weight-bold">{{ urlToSourceName(relatedUrl.url) }}&nbsp;&#187;</span>
            </a>
          </li>
        </ul>
      </div>

      <!-- Status(es) -->
      <div v-if="proceduralStatuses?.length > 0" class="mt-4">
        <span class="text-body-2 font-weight-bold">{{ $t('generic.status') }}</span>
        <ul class="text-body-2 plain-li">
          <li
            v-for="(item, index) in proceduralStatuses"
            :key="index"
            class="mb-1"
          >
            <strong>{{ $moment(item.setDateTime).format('DD-MM-YYYY') }} - </strong>
            <span
              :style="voteResultStatusStyling(item.status)"
            >
            {{ item.status }}
            </span>
          </li>
        </ul>
      </div>

      <!-- Vote results -->
      <div
        v-if="voteResults?.overallResult"
        class="text-body-2 font-weight-bold mt-4"
      >
        <p> <!-- Very similar to the one in BasicDetails.vue -->
          <v-icon color="grey darken-1">mdi-gavel</v-icon>
          <span class="text-body-2 font-weight-bold">{{ $t('generic.status') }}</span>
          <span :style="voteResultStatusStyling(voteResults.overallResult)">
            {{ voteResults.overallResult }}
          </span>
        </p>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { voteResultStatusStyling } from '@/helpers/voteResultsHelper';
import urlToSourceName from '@/helpers/urlToSourceNameHelper';
import ShowMoreButton from './ShowMoreButton.vue';

export default {
  data() {
    return {
      foldedItemsLimit: 4,
      showAllItems: {
        futureEvents: false,
        pastEvents: false,
      },
    };
  },

  props: {
    publicIds: {
      type: Array,
      required: false,
    },
    relatedDossiers: {
      type: Array,
      required: false,
    },
    relatedPeoplePlain: {
      type: Array,
      required: false,
    },
    relatedOrganizationsPlain: {
      type: Array,
      required: false,
    },
    relatedUrls: {
      type: Array,
      required: false,
    },
    futureEvents: {
      type: Array,
      required: false,
    },
    pastEvents: {
      type: Array,
      required: false,
    },
    proceduralStatuses: {
      type: Array,
      required: false,
    },
    voteResults: {
      type: String,
      required: false,
    },
  },

  components: {
    ShowMoreButton,
  },

  computed: {
    // TODO: POLMONNL-6203 - Remove either this or GenericDetailSidebar.shouldShowBasicDetails() ?
    shouldShow() {
      return (this.publicIds?.length
        || this.relatedPeoplePlain?.length
        || this.relatedOrganizationsPlain?.length
        || this.futureEvents?.length
        || this.pastEvents?.length
        || this.proceduralStatuses?.length
        || this.voteResults
      );
    },

    fallbackOrg() {
      return this.$t('detail.related.organizationsRelations.fallback');
    },

    fallbackPerson() {
      return this.$t('detail.related.peopleRelations.fallback');
    },
  },

  methods: {
    voteResultStatusStyling,
    urlToSourceName,

    displayedList(type) {
      if (this.showAllItems[type]) {
        return this[type];
      }
      return this[type].slice(0, this.foldedItemsLimit);
    },

    safeI18n(i18nKey, fallback) {
      const translation = this.$t(i18nKey);
      if (i18nKey === translation) return fallback;
      return translation;
    },
  },
};
</script>

<style lang="scss" scoped>
  a {
    text-decoration: none;

    &.disabled {
      pointer-events: none;
    }
  }
  .past-list{
    list-style-type: none;
    .check:before {
      content: '✓';
      margin-left: -12px;
      margin-right: 3px;
    }
  }
</style>
