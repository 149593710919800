<template>
  <v-expansion-panel
    v-if="hasReferences()"
  >
    <v-expansion-panel-header>{{$t('timeline.relatedDocuments')}}</v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-timeline v-if="items" :reverse="false" dense align-top>
        <v-timeline-item
          v-for="item in items.data.slice(0, size)"
          :key="item.id"
          :color="
            codeToColor(
              item.type &&
                (item.type.startsWith('EUROPARL-') ||
                  item.type.startsWith('EUROCOM-') ||
                  item.type.startsWith('EUROCOU-'))
                ? item.institution
                : item.type
            )
          "
          :icon="`${id && id === item.id ? 'mdi-disc' : ''}`"
          :small="id && id !== item.id"
        >
          <v-card
            class="elevation-2"
            @click.stop.prevent="
              item.type && item.type.startsWith('DH-')
                ? externalUrl(item.url)
                : goToUrlConditionally(item.type, item.id)
            "
          >
            <v-card-title class="text-subtitle-2 mb-n2">
              {{ formattedDate(item.publicationDate) }}
            </v-card-title>
            <v-card-text class="mt-n2">
              {{ itemStripHtml(item.title)
              }}<span v-if="item.fragDocu">
                {{ itemFragDocufy(item.fragDocu) }}</span
              >
            </v-card-text>
            <v-card-text class="text-caption mt-n5 font-weight-bold">
              {{ getCleanedRef(item) }}
              <span v-if="getProcedureNumber(item)"
                ><span class="font-weight-bold">{{ $t('generic.and') }}</span
                >{{ getProcedureNumber(item) }}</span
              >
              <span v-if="item.related && item.related.dossier.dossierId"
                ><span class="font-weight-bold"
                  >{{ item.related.dossier.dossierId }} -
                </span></span
              >
              <span v-if="item.institution" class="font-weight-bold">
                - {{ codeToName(item.institution) }}</span
              >
              <span v-if="item.url">
                <!-- newline here required for space between elems -->
                {{ itemUrlToSourceName(item.url) }}
              </span>
              <span
                v-if="item.type && item.type.startsWith('DH-')"
                class="font-weight-bold"
              >
                - {{ item.documentType }}</span
              >
            </v-card-text>
          </v-card>
        </v-timeline-item>
        <v-btn
          v-if="!(size >= items.data.length)"
          @click.stop="showMore()"
        >
        {{ $t('generic.showMore') }}
        </v-btn>
      </v-timeline>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapGetters } from 'vuex';

import { euInstitutionCodeToName } from '@/helpers/groupingHelper';
import typeToColor from '@/helpers/colorHelper';
import urlToSourceName from '@/helpers/urlToSourceNameHelper';
import cleanReferenceNumber from '@/helpers/cleanReferenceNumberHelper';
import fragDocufy from '@/helpers/fragDocufyHelper';
import { showDialog } from '@/helpers/dialogHelper';
import { stripHtml } from '@/helpers/toolbelt';

import EuTeaserDialog from '@/modules/documentDashboard/components/EuTeaserDialog.vue';
import DutchSenateTeaserDialog from '@/modules/documentDashboard/components/DutchSenateTeaserDialog.vue';
import MissingModuleTeaserDialog from '@/modules/documentDashboard/components/MissingModuleTeaserDialog.vue';

export default {
  name: 'ListReferences',

  props: {
    refs: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      size: 50,
    };
  },

  computed: {
    ...mapGetters({
      authHasModuleAccess: 'authHasModuleAccess',
      items: 'nlRelated',
      userFilters: 'userFilters',
    }),

    cleanedRefs() {
      if (!this.refs || typeof this.refs !== 'object') return [];
      let cleanRefs = [];
      Object.values(this.refs).forEach((refType) => {
        cleanRefs = cleanRefs.concat(
          refType.map((ref) => cleanReferenceNumber(ref)),
        );
      });
      return cleanRefs;
    },
  },

  methods: {
    itemUrlToSourceName(url) {
      return urlToSourceName(url);
    },

    showMore() {
      this.size += 50;
    },

    codeToColor(code) {
      return typeToColor(code);
    },

    itemStripHtml(itemTitle) {
      return stripHtml(itemTitle);
    },

    itemFragDocufy(fragDocu) {
      return fragDocufy(fragDocu);
    },

    hasReferences() {
      // References include the item itself.
      // We only show refs if OTHER references are found.
      if (this.items && this.items.data.length > 1) {
        return true;
      }
      return false;
    },

    codeToName(institutionCode) {
      return euInstitutionCodeToName(institutionCode);
    },

    formattedDate(date) {
      return this.$moment(date).format('DD-MM-YYYY');
    },

    getCleanedRef(item) {
      // Expected case Europarl documents:
      if (item.reference) return cleanReferenceNumber(item.reference);

      // Expected case Tweede en Eerst Kamer documents:
      // Finds a reference the document has in common with the main document.
      // (metadata mentions only)
      // This is a bit hacky, and should be replaced when we start
      // returning the matched references from backend.
      let matchedRef;
      if (item?.metadata?.mentions?.length) {
        const matchedMention = item.metadata.mentions
          .find((mention) => this.cleanedRefs.includes(mention.term));
        matchedRef = matchedMention ? matchedMention.term : null;
      }
      return matchedRef;
    },

    getProcedureNumber(item) {
      const procedures = [];
      if (item.relations) {
        item.relations.forEach((relation) => {
          if (relation.type === 'PROCEDURE') {
            procedures.push(relation.code);
          }
        });
      }
      return procedures.toString().replace(',', ', ');
    },

    externalUrl(url) {
      window.open(url, '_blank');
    },

    actionUrl(type, id) {
      const {
        nlGroupsByDocType,
        euGroupsByDocType,
        nlLrGroupsByDocType,
        beFedGroupsByDocType,
        beFlmGroupsByDocType,
      } = this.userFilters;
      let destination;
      if (nlGroupsByDocType[type]) destination = 'nl';
      else if (euGroupsByDocType[type]) destination = 'eu';
      else if (nlLrGroupsByDocType[type]) destination = 'nl-lr';
      else if (beFedGroupsByDocType[type]) destination = 'be-fed';
      else if (beFlmGroupsByDocType[type]) destination = 'be-flm';
      else throw new Error(`doctype ${type} not found in any dashboard Grouping`);
      return `${destination}/document/${id}`;
    },

    goToUrlConditionally(type, id) {
      const { documentTypesConfig } = this.userFilters;
      const { requiredModules } = documentTypesConfig[type];
      const missingModuleCodes = requiredModules
        .map(({ code }) => code)
        .filter((moduleCode) => !this.authHasModuleAccess(moduleCode));
      if (missingModuleCodes.length) {
        if (missingModuleCodes.includes('MC07')) showDialog(EuTeaserDialog);
        else if (missingModuleCodes.includes('MC02')) showDialog(DutchSenateTeaserDialog);
        // TODO: add specific teaser dialogs for other modules? (NL, BE FED, BE FLM?)
        else showDialog(MissingModuleTeaserDialog);
        return;
      }
      if (this.$vuetify.breakpoint.mdAndDown) {
        this.$router.push({ path: this.actionUrl(type, id) });
      } else {
        window.open(this.actionUrl(type, id), '_blank');
      }
    },
  },

  mounted() {
    if (this.refs && Object.keys(this.refs).length > 0) {
      // TODO: POLMONNL-6203 - Make this call conditional so we don't call it for BE?
      this.$store.dispatch('getRelated', {
        refs: this.refs,
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.v-card--link:before {
  background: none;
}
</style>
